import { useLocation } from "react-router-dom"


export const MobileHeader = ({isMobileHeaderOpen}) => {
    const location = useLocation()
    
    const menus = [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'Our Story',
            path: '/our-story'
        },
        {
            label: 'Menu',
            path: '/menu'
        },
        {
            label: 'Order Now',
            path: '/categories?id=65f56543b309f7f0d7103dca'
        },
        {
            label: 'Branches',
            path: '/branches'
        },
        {
            label: 'Support',
            path: '/support'
        },
    ]
    return (
        <>
                <div className={`menu-left-part ${isMobileHeaderOpen ? 'menu-part-opened' : ''}`}>
                    <nav id="header-main-menu">
                        <ul className="mobile-main-menu">
                            {menus.map(mn => (
                                <li>
                                    <a href={mn.path} className={mn.path == location.pathname ? 'current' : ''}>{mn.label}</a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    {/* <div className="menu-right-text">
                        <p className="menu-text-title">HELLO</p>
                        <div className="menu-text">
                            Ninan's Burger is more than just a place to
                            <span className="menu_primary_text"> grab a bite</span>
                        </div>
                    </div> */}
                </div>
        </>
        
    )
}
